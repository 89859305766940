import React, {useState, useEffect} from 'react';
import {View, TextInput, StyleSheet, Dimensions, ImageBackground, Text, Image, TouchableOpacity} from 'react-native';
import * as Linking from 'expo-linking';
const window = Dimensions.get("window");

const Box_en = ({navigation}) => {
  
    const [dimensions, setDimensions] = useState({ window });

    const onChange = ({ window }) => {
        setDimensions({ window });
    };

	useEffect(() => {
        Dimensions.addEventListener("change", onChange);
        return () => {
        Dimensions.removeEventListener("change", onChange);
        };
    });
  
    return (    
        <View style={styles.container}>
        <View 
            style={[
                styles.box, 
                {
                    width: dimensions.window.width > 900 ? '66.66%' : '100%',
                    height: dimensions.window.width > 900 ? 600: 900,
                },
            ]}
          >
            <View style={[
                styles.innerPhoto, 
                {
                    flexDirection: dimensions.window.width > 900 ? 'row' : 'column', 
                    justifyContent: 'space-between'
                }
            ]}>
                <View 
                    style={{ 
                        height: dimensions.window.width > 900 ? '100%' : '40%', 
                        width: dimensions.window.width > 900 ? '55%' : '100%', 
                        // backgroundColor: 'gray',
                        alignItems: 'center',
                        marginTop: dimensions.window.width > 900 ? 80 : 35, 
                    }}
                >
                    {/* picnicIcon */}
                    <Image
                        resizeMode='contain'
                        style={{ 
                            height: 80, 
                            width:150, 
                            // backgroundColor: 'pink'
                        }}
                        source={require('../../assets/iconWithoutBackground.png')}
                    />
                    {/* text */}
                    <View 
                        style={{ 
                            // backgroundColor: 'violet', 
                            height: dimensions.window.width > 1000 ? '60%' : '40%',
                            justifyContent: 'center',
                            paddingLeft: 
                                dimensions.window.width > 1500 ? 60 
                                : dimensions.window.width > 900 ? 30
                                : 0,
                            width: '95%',
                            paddingTop: dimensions.window.width > 900 ? 30 : 0,
                            marginVertical: dimensions.window.width > 900 ? 0 : 20,                           
                        }}
                    >
                        <Text style={[
                            styles.textStyleTitle, 
                            { 
                                textAlign: dimensions.window.width > 900 ? 'left' : 'center' 
                            }
                        ]}>
                            Making decision with friends has never been easier!
                        </Text>
                        <Text style={[
                            styles.textStyleSubTitle, 
                            { 
                                textAlign: dimensions.window.width > 900 ? 'left' : 'center',
                                paddingTop: dimensions.window.width > 900 ? 30 : 10,
                                paddingHorizontal: dimensions.window.width > 900 ? 0 : 30,
                            }
                        ]}>
                            Forget the endless chat groups!
                        </Text>
                    </View>
                    {/* download APP STORE Buttons */}
                    <View 
                        style={{
                            flexDirection: dimensions.window.width > 1000 ? 'row' : 'column',  
                        }}
                    >
                        <TouchableOpacity
                            style={{height: 40, width: 200, marginBottom: 20, marginTop: 10, }}
                            onPress={() => {
                            navigation.navigate('Download')
                            }}
                        >
                        <Image
                        resizeMode='contain'
                        style={{ height:40}}
                        source={require('../../assets/app-store.png')}
                        />					
                        </TouchableOpacity>

                        {/* download GOOGLE PLAY Buttons */}
                        <TouchableOpacity
                            style={{height: 60, width: 200}}
                            onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=fyi.picnic.picnic&gl=ES') }
                        >
                            <Image
                            resizeMode='contain'
                            style={{ height:60}}
                            source={require('../../assets/google-play-badge.png')}
                            />					
                        </TouchableOpacity>		
                    </View>		
                </View>

                {/* First PHOTO IPHONE */}
                <Image
                    resizeMode='contain'
                    style={{ 
                        height: dimensions.window.width > 900 ? '80%' : '50%', 
                        width: dimensions.window.width > 900 ? '45%' : '100%', 
                        // backgroundColor: 'orange',
                        alignSelf: 'flex-end',                        
                    }}
                    source={require('../../assets/photoWeb_En/1.png')}
                /> 
            </View>
        </View>

        <View 
            style={[
                styles.box, 
                {
                    width: dimensions.window.width > 900 ? '33.33%' : '100%',
                    height: 600,
                },
            ]}
          >
            <View style={styles.innerPhoto}>
                <View style={{height: '20%', justifyContent: 'center'}}>
                    <Text 
                        style={[
                            styles.textStyleTitle, 
                            { 
                                textAlign: 'center',
                                
                            }
                        ]}>
                        Make suggestions{'\n'}and vote!
                    </Text>
                </View>
                {/* second PHOTO IPHONE */}
                <Image
                    resizeMode='contain'
                    style={{ 
                        height: '80%', 
                        width: '100%', 
                        // backgroundColor: 'orange',                      
                    }}
                    source={require('../../assets/photoWeb_En/2.png')}
                />
            </View>
        </View>
        <View 
            style={[
                styles.box, 
                {
                    width: dimensions.window.width > 900 ? '33.33%' : '100%',
                    height: 600,
                },
            ]}
          >
            <View style={styles.innerPhoto}>
                <View style={{height: '20%', justifyContent: 'center'}}>
                    <Text 
                        style={[
                            styles.textStyleTitle, 
                            { 
                                textAlign: 'center',
                                
                            }
                        ]}>
                        Upload photos and links!
                        {/* Organize plans{'\n'}with your friends! */}
                        {/* Make suggestions{'\n'}and vote! */}
                    </Text>
                </View>
                {/* second PHOTO IPHONE */}
                <Image
                    resizeMode='contain'
                    style={{ 
                        height: '80%', 
                        width: '100%', 
                        // backgroundColor: 'orange',                     
                    }}
                    source={require('../../assets/photoWeb_En/3.png')}
                />
            </View>
        </View>

        <View style={[
            styles.box, 
            {
                width: dimensions.window.width > 900 ? '66.66%' : '100%',
                height: 600
            },
            ]}
        >
            <View style={[
                styles.innerPhoto, 
                {
                    flexDirection: dimensions.window.width > 900 ? 'row' : 'column', 
                    justifyContent: 'space-between'
                }
            ]}>
                <View 
                    style={{ 
                        height: dimensions.window.width > 900 ? '100%' : '30%', 
                        width: dimensions.window.width > 900 ? '40%' : '100%', 
                        // backgroundColor: 'gray',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                 >   
                    <View 
                        style={{ 
                            // backgroundColor: 'violet', 
                            height: dimensions.window.width > 1000 ? '60%' : '40%',
                            justifyContent: 'center',
                            paddingLeft: 
                                dimensions.window.width > 1500 ? 60 
                                : dimensions.window.width > 900 ? 30
                                : 0,
                            width: '95%',
                            paddingTop: dimensions.window.width > 900 ? 30 : 0,
                            marginVertical: dimensions.window.width > 900 ? 0 : 20,                           
                        }}
                    >
                        <Text style={[
                            styles.textStyleTitle, 
                            { 
                                textAlign: dimensions.window.width > 900 ? 'left' : 'center' 
                            }
                        ]}>
                            Add questions!
                        </Text>
                        <Text style={[
                            styles.textStyleSubTitle, 
                            { 
                                textAlign: dimensions.window.width > 900 ? 'left' : 'center',
                                paddingTop: dimensions.window.width > 900 ? 30 : 10,
                                paddingHorizontal: dimensions.window.width > 900 ? 0 : 30,
                            }
                        ]}>
                            Where? When could we go?{'\n'}Which restaurant? Budget?
                        </Text>
                    </View>
                </View>
                <Image
                    resizeMode='contain'
                    style={{ 
                        height: dimensions.window.width > 900 ? '100%' : '70%', 
                        width: dimensions.window.width > 900 ? '60%' : '100%', 
                        // backgroundColor: 'orange',                       
                    }}
                    source={require('../../assets/photoWeb_En/4_large.png')}
                />   
            </View>
        </View>

        <View style={[
            styles.box, {
                width: '100%',
                height: dimensions.window.width > 1000 ? 300 : 400,
                paddingBottom: 20,         
                // backgroundColor:'red'    
            },         
        ]}>
            <View style={[
                styles.innerPhoto,
                {
                    justifyContent: 'center'
                }
            ]}>
                <View 
                    style={{ 
                        // backgroundColor: 'violet', 
                        justifyContent: 'center',
                        alignItems:'center',
                        paddingBottom: 20,
                        width: '100%', 
                        flexDirection: dimensions.window.width > 1000 ? 'row' : 'column',              
                    }}
                >
                    <Text 
                        style={[
                            styles.textStyleTitle, 
                            { 
                                textAlign: dimensions.window.width > 900 ? 'left' : 'center',
                                paddingHorizontal: dimensions.window.width > 900 ? 0 : 30,
                                fontSize: 50
                            }
                        ]}>
                        Get
                    </Text>
                    <Image
                        resizeMode='contain'
                        style={{ 
                            height: 80, 
                            width:150, 
                            marginLeft: dimensions.window.width > 900 ? 15 :0,
                            // backgroundColor: 'pink'
                        }}
                        source={require('../../assets/iconWithoutBackground.png')}
                    />
                </View>
                <View 
                    style={{
                        flexDirection: dimensions.window.width > 1000 ? 'row' : 'column',  
                    }}
                >
                    <TouchableOpacity
                        style={{height: 40, width: 200, marginBottom: 20, marginTop: 10, }}
                        onPress={() => Linking.openURL('https://apps.apple.com/us/app/picnic-make-plans-with-friends/id1544428659') }
                    >
                        <Image
                            resizeMode='contain'
                            style={{ height:40}}
                            source={require('../../assets/app-store.png')}
                        />					
                    </TouchableOpacity>

                        {/* download GOOGLE PLAY Buttons */}
                    <TouchableOpacity
                        style={{height: 60, width: 200}}
                        onPress={() => {
                        navigation.navigate('Download')
                        }}
                    >
                        <Image
                            resizeMode='contain'
                            style={{ height:60}}
                            source={require('../../assets/google-play-badge.png')}
                        />					
                    </TouchableOpacity>		
                </View>		
                </View>                
            </View>
            
            <View 
                style={[
                    styles.footage,
                    {
                        justifyContent: dimensions.window.width > 900 ? 'flex-end' : 'space-around',

                    }
                ]}
            >
                
                <TouchableOpacity 
                    style={styles.footageButtonStyle}
                    onPress={() => {
						navigation.navigate('Term')
					}}
                >
                    <Text style={[
                        styles.footageButtonTextStyle, 
                        {
                            paddingHorizontal: dimensions.window.width > 900 ? 30 : 0
                        }
                    ]}
                    >
                        Term
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity 
                    style={styles.footageButtonStyle}
                    onPress={() => {
						navigation.navigate('Privacy')
					}}
                >
                    <Text style={[
                        styles.footageButtonTextStyle, 
                        {
                            paddingHorizontal: dimensions.window.width > 900 ? 30 : 0
                        }
                    ]}
                    >
                        Privacy
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity 
                    style={styles.footageButtonStyle}
                    onPress={() => Linking.openURL('mailto:picnic.fyi@gmail.com') }
                    title="Support picnic" 
                >
                    <Text style={[
                        styles.footageButtonTextStyle, 
                        {
                            paddingHorizontal: dimensions.window.width > 900 ? 30 : 0
                        }
                    ]}
                    >
                        Support
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity 
                    style={styles.footageButtonStyle}
                    onPress={() => Linking.openURL('https://www.giadalanzalaco.com') }
                >
                    <Text style={[
                        styles.footageButtonTextStyle, 
                        {
                            paddingHorizontal: dimensions.window.width > 900 ? 30 : 0
                        }
                    ]}
                    >
                        © Giada Lanzalaco
                    </Text>
                </TouchableOpacity>
                {/* <Text style={styles.footageButtonTextStyle}> © Giada Lanzalaco </Text> */}
            </View>
            
     </View>

  )
}

const styles = StyleSheet.create({
  
    container: {
    flex:1,
    padding: 10,
    flexDirection: 'row',
    flexWrap: 'wrap', 
    // backgroundColor: 'yellow',
  },

  box: {
    padding: 10,
    // backgroundColor:'brown',
  },

  innerPhoto: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#208AFF', 
    borderRadius: 30,
  },

  textStyleTitle:{
    fontFamily: 'SFRounded',
    fontSize: 30,
    color: 'white',
  },

  textStyleSubTitle:{
    fontFamily: 'SFRounded_Regular',
    fontSize: 20,
    color: 'white',
  },

  footage:{
    width: '100%',
    flexDirection: 'row',
    // backgroundColor: 'green',
    height: 90,
    alignItems: 'center'
  },

  footageButtonStyle:{
    // width: 100,
    paddingVertical: 3,
    // backgroundColor:'lime',
    justifyContent: 'center',
    alignItems: 'center' 
  },

  footageButtonTextStyle:{
    fontFamily: 'SFRounded_Regular',
    fontSize: 12,
    color: '#aeaeb2',
    // width: 200,
    // paddingHorizontal: 10,
    paddingVertical: 3,
    // paddingBottom: 20
    // backgroundColor:'blue',
  }

});

export default Box_en;