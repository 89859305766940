import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Dimensions } from 'react-native';
import { Feather } from '@expo/vector-icons'; 
const {width, height} = Dimensions.get('window')

const Privacy = ({navigation}) => {
	return(
		<View style={styles.container}>
		<View style={{flexDirection: 'row', paddingTop: 30, alignItems:'center', justifyContent:'center'}}>
			<View style={{position:'absolute', left:20}}>
			<TouchableOpacity 
				onPress={() => navigation.goBack()}
			>
				<Feather name="chevron-left" size={27} color='white' />
			</TouchableOpacity>
			</View>
			<Text style={{fontSize: 16, fontFamily: 'SFRounded', color: 'white' }}>Privacy</Text>

		</View>	
			<Text style={{padding: 30, fontFamily: 'SFRounded_Regular', color: 'white'}}>
				picnic is a tool for making decisions with friends. Our services provide fast and fun ways to express yourself!
				When you use these services, you’ll share some information with us. So we want to be upfront a
				bout the information we collect, how we use it, 
				whom we share it with, and the controls we give you to access, update, and delete your information.
				That’s why we’ve written this Privacy Policy. And it’s why we’ve tried to write it in a way that’s 
				blissfully free of the legalese that often clouds these documents. 
				Of course, if you still have questions about anything in our Privacy Policy, just contact us.
				You should read our entire Privacy Policy, but when you only have a few minutes or want to remember 
				something later on, you can always take a look at this summary—so you can review some of the basics in just a few minutes.
				Some final notes before we dive in: We’re happy to report that we participate in the EU-U.S. 
				and Swiss-U.S. Privacy Shield, which you can read more about here. And, if you’re in the EU, 
				there’s some extra information we’d like to bring to your attention here.
				Information We Collect There are three basic categories of information we collect:
				Information you choose to give us. Information we get when you use our services. 
				Information we get from third parties. Here’s a little more detail on each of these categories.
				Information You Choose to Give Us When you interact with our services, we collect the information 
				that you choose to share with us. For example, most of our services require you to set up a basic 
				picnic account, so we need to collect a few important details about you, such as: a unique username 
				you’d like to go by, a password, an email address, a phone number, and your date of birth. To make it easier 
				for others to find you, we may also ask you to provide us with some additional information that will be publicly 
				visible on our services, such as profile pictures, a name, or other useful identifying information. 
				Other services, such as commerce products, may also require you to provide us with a debit or credit card 
				number and its associated account information.
				Of course, you’ll also provide us whatever information you post through our services, such as Fictions. 
				Keep in mind that the users who watch your Fictions and any other content to can always save that content 
				or copy it outside the app. So, the same common sense that applies to the internet at large applies to picnic 
				as well: Don’t send messages or share content that you wouldn’t want someone to save or share.
				It probably goes without saying, but we’ll say it anyway: When you contact customer support or communicate 
				with us in any other way, we’ll collect whatever information you volunteer.
				Information We Get When You Use Our Services When you use our services, we collect information about which 
				of those services you’ve used and how you’ve used them. We might know, for instance, that you watched a 
				particular Fiction, saw a specific ad for a certain period of time, and sent a few Fictions to friends. 
				Here’s a fuller explanation of the types of information we collect when you use our services:
				Usage Information. We collect information about your activity through our services. For example, we may 
				collect information about: how you interact with our services, such as which filters you view or apply 
				to Fictions, which Fictions you watch on Discover. how you communicate with other picnic Users, 
				such as their names, the time and date of your communications, the number of messages you exchange with your 
				friends, which friends you exchange messages with the most, and your interactions with messages (such as when you 
				open a message or capture a screenshot). Content Information. We collect content you create on our services, 
				such as information about the content you create or provide, such as if the recipient has viewed the content and 
				the metadata that is provided with the content. Device Information. We collect information from and about the 
				devices you use. For example, we collect: information about your hardware and software, such as the hardware model, 
				operating system version, device memory, advertising identifiers, unique application identifiers, apps installed, 
				unique device identifiers, browser type, language, battery level, and time zone; information from device sensors, 
				such as accelerometers, gyroscopes, compasses, microphones, and whether you have headphones connected; and information 
				about your wireless and mobile network connections, such as mobile phone number, service provider, and signal strength. 
				Device Phonebook. Because picnic is all about communicating with friends, we may—with your permission—collect 
				information from your device’s phonebook. Camera and Photos. Many of our services require us to collect images and 
				other information from your device’s camera and photos. For example, you won’t be able to post Fictions or upload 
				photos from your camera roll unless we can access your camera or photos. Location Information. When you use our services 
				we may collect information about your location. With your permission, we may also collect information about your precise 
				location using methods that include GPS, wireless networks, cell towers, Wi-Fi access points, and other sensors, such as 
				gyroscopes, accelerometers, and compasses. Information Collected by Cookies and Other Technologies. Like most online 
				services and mobile applications, we may use cookies and other technologies, such as web beacons, web storage, and unique 
				advertising identifiers, to collect information about your activity, browser, and device. We may also use these technologies 
				to collect information when you interact with services we offer through one of our partners, such as advertising and commerce 
				features. Most web browsers are set to accept cookies by default. If you prefer, you can usually remove or reject browser 
				cookies through the settings on your browser or device. Keep in mind, though, that removing or rejecting cookies could 
				affect the availability and functionality of our services. To learn more about how we use cookies and your choices, 
				please check out our Cookie Policy. To learn more about how third parties use these technologies on our services, be 
				sure to read the Analytics and Advertising Services Provided by Others section of our Privacy Policy. Log Information. 
				We also collect log information when you use our website. That information includes, among other things: details about 
				how you’ve used our services. device information, such as your web browser type and language. access times. pages viewed. 
				IP address. identifiers associated with cookies or other technologies that may uniquely identify your device or browser. 
				pages you visited before or after navigating to our website. Information We Collect from Third Parties We may 
				collect information about you from other users, our affiliates, and third parties. Here are a few examples:
				If you link your picnic account to another service we may receive information from the other service, like 
				how you use that service. Advertisers may let us know you installed a new app or game so we can determine 
				whether we showed you an ad for it. If you interact with one of our advertisers, they may share information 
				with us to help target or measure the performance of ads. You can learn more about these types of ads in our 
				Support Center. If another user uploads their contact list, we may combine information from that user’s contact 
				list with other information we have collected about you. How We Use Information What do we do with the information 
				we collect? For the detailed answer, go here. The short answer is: Provide you with an amazing set of products 
				and services that we relentlessly improve. Here are the ways we do that:
				develop, operate, improve, deliver, maintain, and protect our products and services. send you communications, 
				including by email. For example, we may use email to respond to support inquiries or to share information about 
				our products, services, and promotional offers that we think may interest you. monitor and analyze trends and usage. 
				personalize our services by, among other things, suggesting friends or profile information, or customizing the content 
				we show you, including ads. contextualize your experience by, among other things, tagging your Memories content 
				using your precise location information (if, of course, you’ve given us permission to collect that information) 
				and applying other labels based on the content. provide and improve ad targeting and measurement, including through 
				the use of your precise location information (again, if you’ve given us permission to collect that information), 
				both on and off our services. See the Control Over Your Information section below for more information about 
				picnic’s advertising practices and your choices. enhance the safety and security of our products and services. 
				verify your identity and prevent fraud or other unauthorized or illegal activity. use information we’ve collected 
				from cookies and other technology to enhance our services and your experience with them. enforce our Terms of Service and other usage policies.
				How We Share Information We may share information about you in the following ways:
				With other picnics. We may share the following information with other picnics: information about 
				you, such as your username, name, and Bitmoji. information about how you have interacted with our services, such 
				as your picnic “score,” the names of picnic Users you are friends with, and other information 
				that will help picnic Users understand your connections with others using our services. For example, 
				because it may not be clear whether a new friend request comes from someone you actually know, we may share 
				whether you and the requestor have picnic friends in common. any additional information you have 
				directed us to share. content you post. How widely your content is shared depends on your personal 
				settings and the type of service you are using. For example, a picnic may be sent to just a single 
				friend you select, but your My Fiction content may be seen by any picnicter whom you allow to see 
				your My Fiction. With all picnic Users, our business partners, and the general public. We may share 
				the following information with all picnic Users as well as with our business partners and the general public: 
				public information like your name, username and profile pictures. Fiction submissions that are set to be viewable 
				by Everyone and any content that you submit to an inherently public service, like Our Fiction and other crowd-sourced services. 
				This content may be viewed and shared by the public at large both on and off our services, including through search results, 
				on websites, in apps, and in online and offline broadcasts. With our affiliates. We may share information with entities 
				within the picnic family of companies. With third parties. We may share your information with the following third 
				parties: With service providers. We may share information about you with service providers who perform services on our behalf. 
				Learn more here. With business partners. We may share information about you with business partners that provide services and functionality. 
				For more information about information collected by third parties on our services, visit our Support Site. 
				With third parties for legal reasons. We may share information about you if we reasonably believe that disclosing 
				the information is needed to: comply with any valid legal process, governmental request, or applicable law, rule, or regulation. 
				investigate, remedy, or enforce potential Terms of Service violations. protect the rights, property, and safety of us, our users, or others. 
				detect and resolve any fraud or security concerns. With third parties as part of a merger or acquisition. If picnic 
				gets involved in a merger, asset sale, financing, liquidation or bankruptcy, or acquisition of all or some portion of our 
				business to another company, we may share your information with that company before and after the transaction closes. 
				We may also share with third parties—such as advertisers—aggregated, non-personally identifiable, or de-identified information.
				Third-Party Content and Integrations Our services may also contain third-party links and search results, 
				include third-party integrations, or offer a co-branded or third-party-branded service. Through these links, 
				third-party integrations, and co-branded or third-party-branded services, you may be providing information (including personal information) 
				directly to the third party, us, or both. You acknowledge and agree that we are not responsible for how those 
				third parties collect or use your information. As always, we encourage you to review the privacy policies of 
				every third-party service that you visit or use, including those third parties you interact with through our services. 
				To learn more about third-party services in picnic, visit our Support Site.
				Analytics and Advertising Services Provided by Others We may let other companies use cookies, web beacons, and similar 
				technologies on our services. These companies may collect information about how you use our services over time and 
				combine it with similar information from other services and companies. This information may be used to, among other 
				things, analyze and track data, determine the popularity of certain content, and better understand your online activity.
				Additionally, some companies, including our affiliates, may use information collected on our services to measure 
				the performance of ads and deliver more relevant ads on behalf of us or other companies, including on third-party 
				websites and apps. For more information about interest-based ads and the choices available to you, go here.
				Provided by Us We may collect information about your activity on third-party services that use cookies and other 
				technologies provided by us. We use this information to improve our advertising services, including measuring the 
				performance of ads and showing you more relevant ads. Visit our Advertising Preferences page to learn more about 
				picnic advertising and how you can control the information used to select the ads you see.
				How Long We Keep Your Information picnic lets you capture what it’s like to live in the moment. On our end, 
				that means most messages—like Fictions and Chats—sent in picnic will be automatically deleted by default from 
				our servers after we detect they’ve been opened by all recipients or have expired. Other content, like Fiction posts, 
				are stored for longer. For detailed information about how long we store different types of content, check out our Support Site.
				We store other information for longer periods of time. For example:
				We store your basic account information—like your name, phone number, and email address—and list of friends until you ask us 
				to delete them. We’re constantly collecting and updating information about the things you might like and dislike, so we can 
				provide you with more relevant content and advertisements. Go here to learn more. If you ever decide to stop using picnic, 
				you can just ask us to delete your account. We’ll also delete most of the information we’ve collected about you after you’ve 
				been inactive for a while—but don’t worry, we’ll try to contact you first!
				Keep in mind that, while our systems are designed to carry out our deletion practices automatically, we cannot promise 
				that deletion will occur within a specific timeframe. There may be legal requirements to store your data and we may need 
				to suspend those deletion practices if we receive valid legal process asking us to preserve content or if we receive reports 
				of abuse or other Terms of Service violations. Finally, we may also retain certain information in backup for a limited period of time or as required by law.
				Control over Your Information We want you to be in control of your information, so we provide you with the following tools.
				Download My Data. You can access and edit most of your basic account information right in our apps. You can also use 
				Download My Data to obtain a copy of information that isn’t available in our apps in a portable format, so you can 
				move it or store it wherever you want. Because your privacy is important to us, we may ask you to verify your identity 
				or provide additional information before we let you access or update your personal information. We may also reject your 
				request to access or update your personal information for a number of reasons, including, for example, that the request 
				risks the privacy of other users or is unlawful. Revoking permissions. If you let us use your information, you can always 
				change your mind and simply revoke your permission by changing the settings on your device if your device offers those 
				options. Of course, if you do that, certain services may lose full functionality. Deletion. While we hope you’ll remain a 
				lifelong picnicter, if for some reason you ever want to delete your account, just go here to learn how. You can also 
				delete some information in the app, like photos you’ve saved to Memories, Our Fiction submissions, and search hiFiction. 
				Advertising Preferences. We try to show you ads that we think will be relevant to your interests. If you would like to 
				modify the information we and our advertising partners use to select these ads, you can do so in the app. Go here to learn 
				more. Communicating with other picnic Users. It’s important to us that you stay in control over whom you communicate with. 
				That’s why we’ve built a number of tools in Settings that let you indicate, among other things, who you want to see your Fictions, 
				whether you’d like to receive Fictions from just your friends or all picnic Users, and whether you’d like to block another 
				picnicter from contacting you again. Go here to learn more. Users in the European Union If you’re a user in the European Union, 
				you should know that picnic is the controller of your personal information. Here is some additional information we would like to bring to your attention:
				Bases for using your information Your country only allows us to use your personal information when certain conditions apply. 
				These conditions are called “legal bases” and, at picnic, we typically rely on one of four:
				Contract. One reason we might use your information is because you’ve entered into an agreement with us. For example, 
				when you buy an On-Demand Geofilter and accepted our Custom Creative Tools Terms, we need to use some of your 
				information to collect payment and make sure we show your Geofilter to the right people at the right place and time. 
				Legitimate interest. Another reason we might use your information is because we have—or a third party has—a legitimate 
				interest in doing so. For example, we need to use your information to provide and improve our services, 
				including protecting your account, delivering your Fictions, providing customer support, and helping you 
				find friends and content we think you’ll like. Because most of our services are free, we also use some 
				information about you to try and show you ads you’ll find interesting. An important point to understand 
				about legitimate interest is that our interests don’t outweigh your right to privacy, so we only rely on 
				legitimate interest when we think the way we are using your data doesn’t significantly impact your privacy 
				or would be expected by you, or there is a compelling reason to do so. We explain our legitimate business 
				reasons for using your information in more detail here. Consent. In some cases we’ll ask for consent to use 
				your information for specific purposes. If we do, we’ll make sure you can revoke your consent in our services 
				or through your device permissions. Even if we’re not relying on consent to use your information, we may ask you 
				for permission to access data like contacts and location. Legal obligation. We may be required to use your personal 
				information to comply with the law, like when we respond to valid legal process or need to take action to protect 
				our users. Since November 15, 2015, our policy has been to notify picnic Users when we receive legal process seeking 
				their account information, with some exceptions. Learn more here. Your right to object You have the right to object to our 
				use of your information. With many types of data, we’ve provided you with the ability to simply delete it if you don’t 
				want us processing it anymore. For other types of data, we’ve given you the ability to stop the use of your data by 
				disabling the feature altogether. You can do these things in the app. If there are other types of information you 
				don’t agree with us processing, you can contact us.
				International transfers We may collect your personal information from, transfer it to, and store and process it in 
				the United States and other countries outside of where you live. Whenever we share information of EU users outside 
				the EU we make sure an adequate transfer mechanism is in place. For internal transfers, we rely on Model Contractual 
				Clauses or the EU-U.S. and Swiss-U.S. Privacy Shield, which you can read more about here. You can find more information 
				on the categories of third parties we share information with here.
				Complaints? If you’re based in the EU, you can always file a complaint with the supervisory authority in your Member 
				State. For example, if you’re based in the UK you can file a complaint with the Information Commissioner’s Office.
				Children Our services are not intended for—and we don’t direct them to—anyone under 13. And that’s why we do not 
				knowingly collect personal information from anyone under 13. In addition, we may limit how we collect, use, and 
				store some of the information of EU users between 13 and 16. In some cases, this means we will be unable to 
				provide certain functionality to these users. If we need to rely on consent as a legal basis for processing your 
				information and your country requires consent from a parent, we may require your parent’s consent before we collect and use that information.
				Revisions to the Privacy Policy We may change this Privacy Policy from time to time. But when we do, 
				we’ll let you know one way or another. Sometimes, we’ll let you know by revising the date at the top of the Privacy Policy 
				that’s available on our website and mobile application. Other times, we may provide you with additional notice (such as adding a 
				statement to our websites’ homepages or providing you with an in-app notification).
			</Text>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: '#208AFF',  
		flex:1,
		// justifyContent: 'center', 
		// alignItems:'center',
	},
})

export default Privacy;