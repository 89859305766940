import React, { useState, useEffect, useContext } from 'react';
import { 
	View, 
	Text, 
	StyleSheet, 
	TouchableOpacity,  
	Dimensions,
	TouchableWithoutFeedback,
	KeyboardAvoidingView,
	Platform,
	Image,
	SafeAreaView
} from 'react-native';
import { Linking } from 'expo';
import { Context as EventContext} from '../context/EventContext';
import { Link } from '@react-navigation/native';
const window = Dimensions.get("window");
import * as Font from 'expo-font';


const {width, height} = Dimensions.get('window')

const WelcomeScreen = ({ navigation }) => {

    const [dimensions, setDimensions] = useState({ window });

    const onChange = ({ window }) => {
        setDimensions({ window });
    };

	useEffect(() => {
        Dimensions.addEventListener("change", onChange);
        return () => {
        Dimensions.removeEventListener("change", onChange);
        };
    });
	const [loaded] = Font.useFonts({
		SFRounded_Semibold: require('../../assets/fonts/SF-Compact-Rounded-Semibold.otf'),
		GDBoing: require('../../assets/fonts/GD-Boing-Bold.otf'),
	});

	const eventStore = useContext(EventContext)

	const [ eventId, setEventId ] = useState(null)
	const [ invitationUrl, setInvitationUrl ] = useState(null)
	
	useEffect(() => {
		async function handleUrl() {
			let url = await Linking.getInitialURL()
			console.log("url: ", url)
			let { path, queryParams } = Linking.parse(url);
			console.log(`path is ${path}, params: ${queryParams}`)
			if (queryParams.eventid) {
				console.log("event id is: ", queryParams.eventid)
                console.log('queryParams ', queryParams)
				setInvitationUrl(queryParams.eventid)
				setEventId(queryParams.eventid)
				eventStore.getEventInfo(queryParams.eventid)
			}
		}
		handleUrl()
	}, []);

    console.log('eventStore: ', eventStore)
	

	return (

        <View style={styles.container}>
            <View style={styles.innerPhoto}>
                <View 
                    style={{ 
                        height: '90%', 
                        width: dimensions.window.width > 900 ? '70%' : '80%', 
                        // backgroundColor: 'gray',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Image
                        resizeMode='contain'
                        style={{ 
                            height: 80, 
                            width:150, 
                            marginBottom: 30,
                            // backgroundColor: 'pink'
                        }}
                        source={require('../../assets/iconWithoutBackground.png')}
                    />

                    {
                        eventStore.state.eventTitle ?     
                        <Text style={styles.textStyleSubTitle}>
                            A friend invited you{'\n'}to participate to the event{'\n'}
                            <View style={{height: 30}}/>
                            <Text style={styles.textStyleTitle}>
                                '{eventStore.state.eventTitle}'                               
                            </Text>
                        </Text>
                        :
                        <Text style={styles.textStyleSubTitle}>Your invitation is expired</Text>
 
                    }
                    {
                        eventStore.state.eventTitle ?  
                        <a 
                        //cambiar por picnicfyi://
                        // href={"exp://localhost:19000/?eventid=" + invitationUrl}
                            href={"picnicfyi://?eventid=" + invitationUrl}
                            style={{"textDecoration":"none"}}
                        >
                            <View 
                                style={{
                                    height: 40, 
                                    width: 135,
                                    marginLeft: '40', 
                                    backgroundColor: 'white', 
                                    marginTop: 40, 
                                    alignItems: 'center', 
                                    justifyContent:'center',
                                    borderRadius:4,
                                    // borderWidth:2,
                                    // borderColor:'white'
                                    
                                }}>
                                <Text style={styles.textStyle_buttonOpen_picnic}>
                                    Open picnic
                                </Text>
                            </View>
                        </a>
                        :
                        null
                    }
                   

            {/* <View style={styles.eventTitleContainer}>
 				<Text style={styles.eventTitle}>
 				{
					eventId !== null ?
					"You've been invited \n to join the event:" + "\n'" + eventStore.state.eventTitle + "'"
					: ""
				}
				</Text>
			</View>
			<View 
				style={styles.containerButtons}>
				
				<a 
				//cambiar por picnicfyi://
				// href={"exp://localhost:19000/?eventid=" + invitationUrl}
				href={"picnicfyi://?eventid=" + invitationUrl}
				style={{"textDecoration":"none"}}
				>
					<View style={styles.button}>
						<Text style={styles.textButtonStyle}>
						Open picnic
						</Text>
					</View>
				</a>		
			</View> */}


                        
                   <View 
                        style={{
                            flexDirection: dimensions.window.width > 1000 ? 'row' : 'column',  
                            marginTop: 30,
                        }}
                    >
                        <TouchableOpacity
                            style={{height: 40, width: 200, marginBottom: 20, marginTop: 10}}
                            onPress={() => Linking.openURL('https://apps.apple.com/us/app/picnic-make-plans-with-friends/id1544428659') }
                        >
                            <Image
                                resizeMode='contain'
                                style={{ height:40}}
                                source={require('../../assets/app-store.png')}
                                />					
                        </TouchableOpacity>

                            {/* download GOOGLE PLAY Buttons */}
                        <TouchableOpacity
                            style={{height: 60, width: 200}}
                            onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=fyi.picnic.picnic&gl=ES') }
                        >
                            <Image
                            resizeMode='contain'
                            style={{ height:60}}
                            source={require('../../assets/google-play-badge.png')}
                            />					
                        </TouchableOpacity>			
                    </View>	
                    <TouchableOpacity 
                        style={styles.footageButtonStyle}
                        onPress={() => {
                            navigation.navigate('Download')
                        }}
                    >
                        <Text style={styles.footageButtonTextStyle}>What is picnic?</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>   
	)
}

const styles = StyleSheet.create({

    container: {
        flex:1,
        height: '100%',
        padding: 20,
        // flexDirection: 'row',
        // flexWrap: 'wrap', 
        backgroundColor: 'white',
        // backgroundColor: 'cyan'
    },

    box: {
        padding: 10,
    },

    innerPhoto: {
        flex: 1,
        height: '100%',
        width: '100%',
        // padding: 10,
        alignItems: 'center',
        backgroundColor: '#208AFF', 
        borderRadius: 30,
        justifyContent: 'center'
    },

	// container: {
	// 	backgroundColor: '#42cdfd', 
	// 	flex:1, 
	// 	justifyContent:'center', 
	// 	alignItems:'center',
	// },	

    textStyleTitle:{
        fontFamily: 'SFRounded',
        fontSize: 30,
        color: 'white',
        textAlign: 'center',
        marginVertical: 30,
        // backgroundColor:'red',
      },
    
      textStyleSubTitle:{
        fontFamily: 'SFRounded_Regular',
        fontSize: 20,
        color: 'white',
        textAlign: 'center',
        lineHeight: 25,
      },

      footageButtonStyle:{
        // width: 100,
        paddingTop: 30,
        // backgroundColor:'lime',
        justifyContent: 'center',
        alignItems: 'center',

      },
    
      footageButtonTextStyle:{
        fontFamily: 'SFRounded_Regular',
        fontSize: 15,
        color: '#aeaeb2',
        // width: 200,
        // paddingHorizontal: 10,
        paddingBottom: 20,
        color:'white'
        // backgroundColor:'blue',
      },

      textStyle_buttonOpen_picnic: {
        fontFamily:'SFRounded',
        fontSize: 18,
        color:'black'
      }
    
});

export default WelcomeScreen;
    
// {/* <View style={{marginTop: 50, width: width, height: height/4}}>
//           <Image
//             resizeMode='contain'
//             style={{ height:'50%', width:'100%'}}
//             source={require('../../assets/iconWithoutBackground.png')}
//         />
//         </View>  
// 	        <View style={styles.eventTitleContainer}>
// 				<Text style={styles.eventTitle}>
// 				{
// 					eventStore.state.eventTitle ?
// 					"You've been invited \n to join the event:" + "\n'" + eventStore.state.eventTitle + "'"
// 					: ""
// 				}
// 				</Text>
// 			</View>
// 			<View 
// 				style={styles.containerButtons}>
				
// 				<a 
// 				//cambiar por picnicfyi://
// 				// href={"exp://localhost:19000/?eventid=" + invitationUrl}
// 				href={"picnicfyi://?eventid=" + invitationUrl}
// 				style={{"textDecoration":"none"}}
// 				>
// 					<View style={styles.button}>
// 						<Text style={styles.textButtonStyle}>
// 						Open picnic
// 						</Text>
// 					</View>
// 				</a>		
// 			</View> */}
