import React, { useReducer } from 'react';
import createDataContext from './createDataContext';
import firebase from '../../firebaseConfig';

//Organization of Context
//
//eventId: {
// 		eventId: eventId,
// 		eventTitle: eventTitle,
// 		widgetId: widgetId,
// 		usernameUploader: usernameUploader, 		
//		eventColor: eventColor,
// 	}
//

const eventReducer = (state, action) => {
	switch (action.type) {
		
		case 'get_event_info':
			const eventInfo = action.payload
			console.log("eventInfo:", action.payload)			
			return { ...state, ...eventInfo }


		case 'clean_state':
			return {}	


		default:
			return state;
	}		
};

// is called in scr/screens/HomeScreen ----> inizalizeApp()
const getEventInfo = dispatch => {
	return (eventId) => {
		console.log('eventId:', eventId)
		var eventInfo ={}
		firebase
		.firestore()
		.collection("events")
		.doc(eventId)
		.get()
		.then(function(doc) {
		if (doc.exists) {
			console.log("Document data:", doc.data())
			eventInfo = doc.data()
			console.log('eventInfo: ', eventInfo)
			
			dispatch({ 
				type: 'get_event_info', 
				payload: eventInfo 
			})

		} else {
			    // doc.data() will be undefined in this case
			console.log("No such document!");
		}
		}).catch(function(error) {
			console.log("Error getting document:", error);
		});
		
		
	}
}	


const cleanState = dispatch => {
	return () => {
		dispatch({
			type: 'clean_state'
		})
	}
}


export const { Context, Provider } = createDataContext(
	eventReducer, 
	{ getEventInfo, cleanState},
	{}
)