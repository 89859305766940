import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Dimensions } from 'react-native';
import { Feather } from '@expo/vector-icons'; 
const {width, height} = Dimensions.get('window')

const Term = ({navigation}) => {
	return(
		<View style={styles.container}>
		<View style={{flexDirection: 'row', paddingTop: 30, alignItems:'center', justifyContent:'center'}}>
			<View style={{position:'absolute', left:20}}>
			<TouchableOpacity 
				onPress={() => navigation.goBack()}
			>
				<Feather name="chevron-left" size={27} color='white' />
			</TouchableOpacity>
			</View>
			<Text style={{fontSize: 16, fontFamily: 'SFRounded', color: 'white' }}>Terms of Service</Text>

		</View>	
			<Text style={{padding: 30, fontFamily: 'SFRounded_Regular', color: 'white'}}>
				We’ve drafted these Terms of Service (which we call the “Terms”) so you’ll know the rules that govern our relationship with you. 
				Although we have tried our best to strip the legalese from the Terms, there are places where these Terms may still read like a 
				traditional contract. There’s a good reason for that: these Terms do indeed form a legally binding contract between you and picnic. 
				So please read them carefully. In order to use picnic, you must have accepted our Terms and Privacy Policy, which are presented 
				to you (i) when you first open the app and (ii) when we make any material changes to the Terms or the Privacy Policy. Of course, if you don’t 
				accept them, then don’t use the Services. ARBITRATION NOTICE: IF YOU’RE USING THE SERVICES ON BEHALF OF A BUSINESS, THEN YOUR BUSINESS WILL 
				BE BOUND BY THE ARBITRATION CLAUSE THAT APPEARS LATER IN THESE TERMS. 1. Who can use the Services No one under 13 is allowed to create an 
				account or use the Services. We may offer additional Services with additional terms that may require you to be even older to use them. So please 
				read all terms carefully. By using the Services, you state that: you can form a binding contract with picnic. you are not a person who 
				is barred from receiving the Services under the laws of the United States, the United Kingdom, or any other applicable jurisdiction—including, 
				for example, that you do not appear on the U.S. Treasury Department’s list of Specially Designated Nationals or face any other similar prohibition. 
				you are not a convicted sex offender. you will comply with these Terms and all applicable local, state, national, and international laws, rules, 
				and regulations. If you are using the Services on behalf of a business or some other entity, you state that you are authorised to grant all licences 
				set out in these Terms and to agree to these Terms on behalf of the business or entity. 2. Rights we grant you. picnic grants you a personal, 
				worldwide, royalty-free, non-assignable, non-exclusive, revocable, and non-sublicensable licence to access and use the Services. This licence is for 
				the sole purpose of letting you use and enjoy the Services’ benefits in a way that these Terms and our usage policies, such as our Community Guidelines, 
				allow. Any software that we provide you may automatically download and install upgrades, updates, or other new features. You may be able to adjust 
				these automatic downloads through your device’s settings. You may not copy, modify, distribute, sell, or lease any part of our Services. Nor may 
				you reverse engineer or attempt to extract the source code of that software, unless laws prohibit these restrictions or you have our written permission 
				to do so. 3. Rights you grant us. Many of our Services let you create, upload, post, send, receive, and store content. When you do that, you retain 
				whatever ownership rights in that content you had to begin with. But you grant us a licence to use that content as long as it's public on your profile. 
				That licence ends if you decide to delete the content from your profile. How broad that licence is depends on which Services you use and the settings 
				you have selected. We call Fiction submissions that are set to be viewable by Everyone as well as content you submit to crowd-sourced Services” 
				For all content you submit to the Services other than Public Content, you grant picnic royalty-free, sublicensable, and transferable licence to 
				host, store, use, display, reproduce, modify, adapt, edit, publish, and distribute that content for as long as you use the Services. This licence is 
				for the limited purpose of operating, developing, providing, promoting, and improving the Services and researching and developing new ones. Because 
				Public Content is public by nature and records matters of public interest, the licence you grant us for this content is broader. In addition to 
				granting us the rights mentioned in the previous paragraph, you also grant us a licence to create derivative works from, promote, exhibit, broadcast, 
				syndicate, sublicence, publicly perform and publicly display Public Content in any form and in any and all media or distribution methods (now known or 
				later developed). To the extent it’s necessary, when you appear in, create, upload, post or send Public Content, you also grant picnic the 
				unrestricted, worldwide right and licence to use your name, likeness, and voice. This means, among other things, that you will not be entitled to any 
				compensation from picnic, or our affiliates and business partners if your name, likeness, or voice is conveyed through the Services, either on 
				the picnic application or on one of our business partners’ platforms. We reserve the right to delete any content (i) which we think violates 
				these Terms, or (ii) if necessary to comply with our legal obligations. However, you alone, remain responsible for the content you create, upload, 
				post, send, or store through the Services. We always love to hear from our users. But if you provide feedback or suggestions, just know that we 
				can use them without compensating you, and without any restriction or obligation to you. 4. The content of others Much of the content on our 
				Services is produced by users, publishers, and other third parties. Whether that content is posted publicly or sent privately, the content is the 
				sole responsibility of the person or organisation that submitted it. Although picnic reserves the right to review all content that appears 
				on the Services and to remove any content that violates these Terms, or the law, we do not necessarily review all of it. Through these Terms, 
				we make clear that we don't want the Services to be put to bad uses. But because we don't review all content, we cannot guarantee that content on 
				the Services will always conform to our Terms 5. Privacy Your privacy matters to us. You can learn how your information is handled when you use 
				our Services by reading the Privacy Policy. 6. Respecting other people’s rights picnic respects the rights of others. And so should you. 
				You therefore may not use the Services, or enable anyone else to use the Services, in a manner that: violates or infringes someone else’s rights 
				of publicity, privacy, copyright, trademark, or other intellectual-property right; bullies, harasses, or intimidates; defames; spams or solicits our 
				users. In short: You may not use the Services or the content on the Services in ways that are not authorised by these Terms. Nor may you help anyone 
				else in doing so. You must also respect picnic’s rights and adhere to the Brand Guidelines and any other brand guidelines published by picnic
				. You may not do any of the following (or enable anyone else to do so): use branding, logos, designs, photographs, videos, or any other 
				materials used in our Services, except as explicitly allowed by the Brand Guidelines or other brand guidelines published by picnic. copy, archive, 
				download, upload, distribute, syndicate, broadcast, perform, display, make available, or otherwise use any portion of the Services or the content on the 
				Services except as set forth in these Terms. use the Services, any tools provided by the Services, or any content on the Services for any commercial purposes 
				without our consent. 7. Respecting copyright picnic honors copyright laws, including the Digital Millennium Copyright Act. We therefore take reasonable 
				steps to expeditiously remove from our Services any infringing material that we become aware of. And if picnic becomes aware that one of its users has 
				repeatedly infringed copyrights, we will take reasonable steps within our power to terminate the user’s account. We make it easy for you to report 
				suspected copyright infringement. If you believe that anything on the Services infringes a copyright that you own or control, please report it using 
				this email: giadalanzalaco@gmail.com . Don’t use this email address for anything other than reporting copyright infringement, as such emails 
				will be ignored. To report other forms of infringement, please use the tool accessible here. If you file a notice with our Copyright Agent, it must: 
			 	contain the physical or electronic signature of a person authorised to act on behalf of the copyright owner; identify the copyrighted work claimed to 
				have been infringed; identify the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed, or 
				access to which is to be disabled, and information reasonably sufficient to let us locate the material; provide your contact information, including your 
				address, telephone number, and an email address; provide a personal statement that you have a good-faith belief that the use of the material in the manner 
				complained of is not authorised by the copyright owner, its agent, or the law; provide a statement that the information in the notification is accurate and, 
				under penalty of perjury, that you are authorised to act on behalf of the copyright owner. 8. Safety We try hard to keep our Services a safe place for all 
				users. But we can’t guarantee it. That’s where you come in. By using the Services, you agree that: You will not use the Services for any purpose that is 
				illegal or prohibited in these Terms. You will not use any robot, spider, crawler, scraper, or other automated means or interface to access the Services or 
				extract other users’ information. You will not use or develop any third-party applications that interact with the Services or other users’ content or 
				information without our written consent. You will not use the Services in a way that could interfere with, disrupt, affect negatively, or inhibit other 
				users from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the Services. You will not use or attempt 
				to use another user’s account, username, or password without their permission. You will not solicit login credentials from another user. You will not post 
				content that contains pornography, graphic violence, threats, hate speech, or incitements to violence. You will not upload viruses or other malicious code 
				or otherwise compromise the security of the Services. You will not attempt to circumvent any content-filtering techniques we employ, or attempt to access 
				areas or features of the Services that you are not authorised to access. You will not probe, scan, or test the vulnerability of our Services or any system 
				or network. You will not encourage or promote any activity that violates these Terms. We also care about your safety while using our Services. So do not 
				use our Services in a way that would distract you from obeying traffic or safety laws. For example, never picnic and drive. And never put yourself 
				or others in harm’s way just to capture a picnic. 9. Your account You are responsible for any activity that occurs in your picnic account. 
				So it’s important that you keep your account secure. One way to do that is to select a strong password that you don’t use for any other account. By using 
				the Services, you agree that, in addition to exercising common sense: You will not create more than 1 account for yourself. You will not create another 
				account if we have already disabled your account, unless you have our written permission to do so. You will not buy, sell, rent or lease access to your 
				picnic account, picnics, a picnic username or a friend link without our written permission. You will not share your password. You will 
				not log in or attempt to access the Services through unauthorised third-party applications or clients. If you think that someone has gained access to your 
				account, please reach out immediately to picnic Support. 10. Data charges and mobile phones You are responsible for any mobile charges that you 
				may incur for using our Services, including text-messaging and data charges. If you’re unsure what those charges may be, you should ask your service 
				provider before using the Services. If you change or deactivate the mobile phone number that you used to create a picnic account, you must 
				update your account information through Settings within 72 hours to prevent us from sending to someone else messages intended for you. 11. Third-party 
				services If you use a service, feature or functionality that is operated by a third party and made available through our Services (including Services we 
				offer jointly with the third party), each party’s terms will govern the respective party’s relationship with you. picnic is not responsible or 
				liable for a third party’s terms or actions taken under the third party’s terms. 12. Modifying the Services and termination We’re relentlessly 
				improving our Services and creating new ones all the time. This means that we may add or remove features, products, or functionalities, and we may 
				also suspend or stop the Services altogether. We may take any of these actions at any time, and when we do, we will try to notify you beforehand - 
				but this won’t always be possible. Though we hope you remain a lifelong picnicer, you can terminate these Terms at any time and for any reason 
				by deleting your account. picnic may also terminate these Terms with you if you fail to comply with these Terms, our Community Guidelines or the 
				law, or for any reason outside of our control. And while we’ll try to give you advance notice, we can’t guarantee it. Our right to terminate these Terms 
				means that we may stop providing you with any Services, or impose new or additional limits on your ability to use the Services. For example, we may deactivate 
				our account due to prolonged inactivity, and we may reclaim your username at any time for any reason. Regardless of who terminates these Terms, both you 
				and picnic continue to be bound by Sections 3, 6, 9, 10 and 13-22 of the Terms. 13. Indemnity You agree, to the extent permitted by law, to indemnify, 
				defend and hold harmless picnic and our affiliates, directors, officers, stockholders, employees, licensors, suppliers, and agents from and against any 
				complaints, charges, claims, damages, losses, costs, liabilities and expenses (including attorneys’ fees) due to, arising out of, or relating in any way to: 
				(a) your access to or use of the Services; (b) your content; and (c) your breach of these Terms. 14. Disclaimers We try to keep the Services up and running 
				and free of annoyances. But we cannot promise that we will always succeed. The Services are provided “as is” and “as available” and to the extent permitted 
				by law without warranties of any kind, either express or implied, including, in particular implied warranties, conditions, or other terms relating to (i) 
				merchantability, satisfactory quality, fitness for a particular purpose, title, quiet enjoyment, non-infringement, or (ii) arising from a course of dealing. 
				In addition, while picnic attempts to provide a good user experience, we do not represent or warrant that: (a) the Services will always be secure, 
				error-free or timely; (b) the Services will always function without delays, disruption or imperfections; or (c) that any content or information you obtain 
				through the Services will be timely or accurate. IF THE LAW OF THE COUNTRY WHERE YOU LIVE DOES NOT ALLOW THE EXCLUSIONS OF LIABILITY PROVIDED FOR IN 
				THIS CLAUSE, THOSE EXCLUSIONS SHALL NOT APPLY. picnic and their affiliates take no responsibility and assume no liability for any content that you, 
				another user, or a third party creates, uploads, posts, sends, receives, or stores on or through our Services. You understand and agree that you may be 
				exposed to content that might be offensive, illegal, misleading, or otherwise inappropriate, none of which picnic, picnic., nor their 
				affiliates will be responsible for. Nothing in these Terms will exclude or limit any responsibility we may have to remove content if so required 
				by the law of the country where you live. 15. Limitation of liability picnic and our affiliates, directors, officers, stockholders, employees, 
				licensors, suppliers, and agents will not be liable for any indirect, incidental, special, consequential, punitive, or multiple damages, or any loss of 
				profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill or other intangible losses, resulting from: (a) your 
				use of the Services or inability to use the Services; (b) your access to or inability to access the Services; (c) the conduct or content of other users 
				or third parties on or through the Services; or (d) unauthorised access, use or alteration of your content. In no event will picnic, picnic. 
				or their affiliates’ aggregate liability for all claims relating to the Services exceed the greater of €100 EUR or the amount you paid picnic 
				in the last 12 months for any paid Services. IF THE LAW OF THE COUNTRY WHERE YOU LIVE DOES NOT ALLOW ANY LIMITATION OF LIABILITY PROVIDED FOR IN 
				THIS CLAUSE, THAT LIMITATION WILL NOT APPLY. 16. Dispute resolution, arbitration If you have a concern, let’s talk. Go ahead and contact us first 
				and we’ll do our best to resolve the issue.Some of our Services may have additional terms that contain dispute-resolution provisions unique to that 
				Service or your residency. If you are using the Services on behalf of a business (rather than for your personal use), you and picnic agree that 
				to the extent permitted by law, all claims and disputes between us arising out of or relating to these Terms or the use of the Services will be finally 
				settled under the LCIA Arbitration Rules, which are incorporated by reference into this clause. There will be one arbitrator (to be appointed by the LCIA), 
				the arbitration will take place in London, and the arbitration will be conducted in English. If you do not wish to agree to this clause, you must not use 
				the Services. 17. Exclusive venue To the extent the parties are permitted under these Terms to initiate litigation in a court, both you and picnic 
				agree that all claims and disputes (whether contractual or otherwise) arising out of or relating to the Terms or the use of the Services will be litigated 
				exclusively in the courts of England in the United Kingdom, unless this is prohibited by the laws of the country where you reside. You and picnic consent 
				to the exclusive jurisdiction of those courts. 18. Choice of law The laws of England and Wales govern these Terms and any claims and disputes (whether 
				contractual or otherwise) arising out of or relating to these Terms or their subject matter. The courts in some countries may not apply the laws of 
				England and Wales to some disputes related to these Terms. If you reside in one of those countries, the laws of your home country may apply to those disputes. 
				19. Severability If any provision of these Terms is found unenforceable, then that provision will be severed from these Terms and not affect the validity 
				and enforceability of any remaining provisions. 20. Additional terms for specific Services Given the breadth of our Services, we sometimes need to craft 
				additional terms and conditions for specific Services. Those additional terms and conditions, which will be presented to you before you access the relevant 
				Services, then become part of your agreement with us when you accept them. If any part of those additional terms and conditions conflicts with these Terms, 
				he additional terms and conditions will prevail. 21. Final terms These Terms make up the entire agreement between you and picnic and supersede any 
				prior agreements. These Terms do not create or confer any third-party beneficiary rights. If we do not enforce a provision in these Terms, it will not be 
				considered a waiver. We reserve all rights not expressly granted to you. You may not transfer any of your rights or obligations under these Terms without 
				our consent. Contact us picnic welcomes comments, questions, concerns, or suggestions. The person responsible for the Services is Giada Lanzalaco, 
				located in Madrid. Email: picnic.fyi@gmail.com "
			</Text>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: '#208AFF', 
		flex:1,
		// justifyContent: 'center', 
		// alignItems:'center',
	},
})

export default Term;