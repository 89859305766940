import firebase from "firebase"

import "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyCC_zr1u3ngaHM83xe9BjyDXSYy2fCXe4E",
    authDomain: "event-app-7b8b1.firebaseapp.com",
    databaseURL: "https://event-app-7b8b1.firebaseio.com",
    projectId: "event-app-7b8b1",
    storageBucket: "event-app-7b8b1.appspot.com",
    messagingSenderId: "254858402405",
    appId: "1:254858402405:web:f8c4e36413dc633c41848c",
    measurementId: "G-9EP4T5N696"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// firebase.analytics();
export default firebase