import React, {useState, useEffect} from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Dimensions, SafeAreaView, Image } from 'react-native';
import Box_ita from '../components/Box_ita.js'
import Box_en from '../components/Box_en.js'
import Box_es from '../components/Box_es.js'
import { Fontisto } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons'; 
const window = Dimensions.get("window");

const {width, height} = Dimensions.get('window')

const Download = ({navigation}) => {

    const [language, setLanguage] = useState('english')
    const [dimensions, setDimensions] = useState({ window });

    console.log('language: ', language)
    const onChange = ({ window }) => {
        setDimensions({ window });
    };

	useEffect(() => {
        Dimensions.addEventListener("change", onChange);
        return () => {
        Dimensions.removeEventListener("change", onChange);
        };
    });
    console.log('width: ', width)

	return(
        <View>
            <View style={[ 
                styles.header,
                {
                    justifyContent: dimensions.window.width > 500 ? 'flex-end' : 'space-around',
                    paddingRight: dimensions.window.width > 500 ? 20 : 0,
                }
            ]}>
                <TouchableOpacity 
                    style={styles.headerButtonStyle}
                    onPress={() => setLanguage('italian')}
                >
                    <Text style={styles.headerButtonTextStyle}>Italiano</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={styles.headerButtonStyle}
                    onPress={() => setLanguage('english')}
                >
                    <Text style={styles.headerButtonTextStyle}>English</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={styles.headerButtonStyle}
                    onPress={() => setLanguage('spanish')}
                >
                    <Text style={styles.headerButtonTextStyle}>Español</Text>
                </TouchableOpacity>
            </View>
		    <View style={styles.container}>
            {
                language === 'italian' ? 
                <Box_ita
                    navigation={navigation}
                />  
                : language === 'spanish' ? 
                <Box_es
                    navigation={navigation}
                /> 
                : 
                <Box_en
                    navigation={navigation}
                /> 
            }        
		</View>
        </View>


	)
};


const styles = StyleSheet.create({

  	container: {
		backgroundColor: 'white', 
		// height: '100%',
        flex:1
	},

    header:{
        // width: '100%',
        flexDirection: 'row',
        backgroundColor: 'white',
        // height: 60,
        paddingTop: 20,
        alignItems: 'center'
    },
    
    headerButtonStyle:{
        // width: 100,
        paddingVertical: 3,
        // backgroundColor:'lime',
        justifyContent: 'center',
        alignItems: 'center' 
    },
    
   headerButtonTextStyle:{
        fontFamily: 'SFRounded_Regular',
        fontSize: 12,
        color: '#aeaeb2',
        // width: 200,
        paddingHorizontal: 10,
        paddingVertical: 3,
        // paddingBottom: 20
        // backgroundColor:'blue',
    }

});

export default Download;