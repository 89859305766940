import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import WelcomeScreen from './src/screens/WelcomeScreen';
import Download from './src/screens/Download';

import Privacy from './src/screens/Privacy';
import Term from './src/screens/Term';

import * as Font from 'expo-font';

import { Provider as EventProvider } from './src/context/EventContext';

const Stack = createStackNavigator();



const config = {
  screens: {
    Welcome: { path: "" },
    Privacy: 'privacy',
    Download: 'download'
  },
};

const linking = {
  config,
};

export default function App() {

  const [loaded] = Font.useFonts({
    SFRounded: require('./assets/fonts/SF-Compact-Rounded-Bold.otf'),
    SFRounded_Semibold: require('./assets/fonts/SF-Compact-Rounded-Semibold.otf'),
    SFRounded_Regular: require('./assets/fonts/SF-Compact-Rounded-Regular.otf'),
    SFRounded_Medium: require('./assets/fonts/SF-Compact-Rounded-Medium.otf'),
  });

  if (!loaded) {
    return null;
  }

  return (
    <EventProvider>
    <NavigationContainer 
    linking={linking}
    >
      <Stack.Navigator initialRouteName= "Downolad">

        <Stack.Screen
          name="Welcome" 
          component={WelcomeScreen} 
          options={{ 
            headerShown: false
          }}
        />
        <Stack.Screen
          name="Download" 
          component={Download} 
          options={{ 
            headerShown: false
          }}
        />     
        <Stack.Screen
          name="Privacy" 
          component={Privacy} 
          options={{ 
            headerShown: false
          }}
        />  
        <Stack.Screen
          name="Term" 
          component={Term} 
          options={{ 
            headerShown: false
          }}
        />                
      </Stack.Navigator>    
    </NavigationContainer>
    </EventProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
