import React, { useReducer } from 'react';

// function to create a new Data Context automatically
export default (reducer, actions, initialState) => {
	const Context = React.createContext();

	const Provider = ({ children }) => {
		const [state, dispatch] = useReducer(reducer, initialState);

		//actions === { addEventPost: (dispatch) => {return () => {} } }
		const boundActions = {};
		for (let key in actions) {
			boundActions[key] = actions[key](dispatch)
		}

		return <Context.Provider value={{ state, ...boundActions }}>
			{children}
		</Context.Provider>	
	}

	return { Context, Provider }
};